import Axios from './Axios';
import type {RecordType} from 'shared/types';

type ResponseType = {
    total: number;
    data: any[];
    errors?: string;
    success: boolean;
};

export const getRecords = async (endPoint?: string): Promise<RecordType> => {
    if (typeof endPoint === 'undefined' || endPoint === '') {
        throw new Error('Please provide api endpoint');
    }

    const {data}: {data: ResponseType} = await Axios.instance().get(endPoint);

    if (data.success) {
        return {
            total: data.total,
            records: data.data,
        };
    } else {
        throw new Error(data.errors);
    }
};
