import {invalidateUser} from 'components/customer/Auth/store/userSlice';
import {store} from 'store/customer/storeSetup';
import {RoomRepository} from 'IndexedDB';
import Axios from './Axios';

const roomRepository = new RoomRepository();

/**
 *
 * @param {boolean} mock Default value is false
 *
 * @return {Promise}
 */
export const getSubscriptionList = async () => {
    try {
        const response = await Axios.instance().get(`/subscriptions`);

        if (response.data.success && response.data.data) {
            return response.data.data;
        }
    } catch (e) {
        throw e;
    }
};

export const updateDeliveryAddress = async (data) => {
    const response = await Axios.instance().post(
        'settings/delivery-address',
        data,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }
    );

    if (response.data.success) {
        store.dispatch(invalidateUser());

        return response.data.data;
    }
};

export const updateDetails = async (data, values) => {
    const response = await Axios.instance().post('settings/details', data, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
    if (response.data.success) {
        store.dispatch(invalidateUser());
        return response.data.data;
    }
};

export const updatePassword = async (data) => {
    const response = await Axios.instance().post(
        'settings/update-password',
        data,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }
    );
    if (response.data.success) {
        return response.data.message;
    } else {
        return response.data.error;
    }
};

export const deleteCabinetSizes = async (id) => {
    const response = await Axios.instance().delete(
        `settings/cabinet-sizes/delete?sizeId=${id}`
    );

    roomRepository.delete(roomRepository.record.productSizeDefaults, id);

    return !!response.data.success;
};

export const saveCabinetSizes = async (data) => {
    if (isNaN(data.id)) {
        data.id = -1;
    }
    const url = `settings/cabinet-sizes/save?sizeId=${data.id}`;

    const response = await Axios.instance().post(url, data);
    if (response.data.success) {
        data.id = parseInt(response.data.id);
        await roomRepository.add(
            roomRepository.record.productSizeDefaults,
            data,
            true
        );
        return response.data.id;
    } else {
        return response.data.error;
    }
};

export const saveGapSizes = async (data) => {
    if (isNaN(data.id)) {
        data.id = -1;
    }
    const url = `settings/gaps/save?gapId=${data.id}`;

    const response = await Axios.instance().post(url, data);

    if (response.data.success) {
        data.id = parseInt(response.data.id);
        await roomRepository.add(
            roomRepository.record.gapSizeDefaults,
            data,
            true
        );
        return response.data.id;
    } else {
        return response.data.error;
    }
};

export const deleteGapSizes = async (id) => {
    const response = await Axios.instance().delete(
        `settings/gaps/delete?gapId=${id}`
    );

    roomRepository.delete(roomRepository.record.gapSizeDefaults, id);

    return !!response.data.success;
};

export const saveMaterialDefaults = async (data) => {
    const response = await Axios.instance().post(
        '/settings/defaults/save',
        data
    );

    if (response.data.success) {
        store.dispatch(invalidateUser());
        return response.data.messages;
    } else {
        return response.data.error;
    }
};

export const saveManageFavourites = async (productList, sundryList) => {
    const url = `settings/favourites/save`;
    const data = {
        productList: productList,
        sundryList: sundryList,
    };
    const response = await Axios.instance().post(url, data);

    return response.data.message;
};

export const setProductSizeDefault = async (id) => {
    await Axios.instance().post(`/settings/cabinet-sizes/` + id, id);
};

export const setGapSizeDefault = async (id) => {
    await Axios.instance().post(`/settings/gaps/` + id, id);
};
