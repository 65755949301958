import Axios from './Axios';

export const getLeadTimeData = async () => {
    try {
        const response = await Axios.instance().get(
            'reporting/manufacturer/leadtimes'
        );

        if (response.status == 200) {
            const values = response.data.leadtimes.map((leadtime) => [
                leadtime.name,
                leadtime.flatpack,
                leadtime.assembled,
            ]);

            values.splice(0, 0, ['Name', 'Flatpack', 'Assembled']);

            return values;
        }
    } catch (e) {
        return [];
    }
};
