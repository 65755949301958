import {Config} from 'config';
import Axios from './Axios';

export const createBenchtop = async (jobId, roomId, type) => {
    const response = await Axios.instance(Config.app).post(
        `jobs/${jobId}/room/${roomId}/benchtop`,
        {
            type,
        }
    );

    if (response.data.success) {
        return {
            additional_safe: response.data.additional_safe,
            data: response.data.data,
            username: response.data.username,
        };
    }
};

export const loginBTD = async (username) => {
    const response = await Axios.instance(Config.app).post(`benchtops/login`, {
        username,
    });

    if (response.data.success) {
        return response.data.additional_safe;
    }
};

export const saveBenchtop = async (jobId, roomId, benchId) => {
    await Axios.instance(Config.app).put(
        `jobs/${jobId}/room/${roomId}/benchtop/${benchId}`
    );
};

export const deleteBenchtop = async (jobId, roomId, benchId) => {
    const response = await Axios.instance(Config.app).delete(
        `jobs/${jobId}/room/${roomId}/benchtop/${benchId}`
    );

    if (!response.data.success && response.data.error_string)
        throw new Error(response.data.error_string);
    else if (!response.data.success && !response.data.error_string)
        throw new Error('Could not delete Benchtop, please try again later.');
};
