import {UserRepository} from 'IndexedDB';
import Axios from './Axios';
import {Response} from 'service';
import {User} from 'components/customer/Auth/store/userSlice';

// TODO: Move this to proper place
interface Message {
    id: number;
    user_level: number;
    user_id: number;
    heading: string;
    text: string;
    redirect: string;
    expiry_date: string;
}

interface MessagesInterface extends Response {
    data: Message[];
}

const userRepository = new UserRepository();

export const getUserDetails = async () => {
    const response = await Axios.instance().get<{success: number; user: User}>(
        `user`
    );

    if (response.data.success) {
        return response.data.user;
    }
};

export const getAllMessages = async (): Promise<Message[]> => {
    const response = await Axios.instance().get<MessagesInterface>('messages');
    if (response.data.success) {
        return response.data.data;
    }
};

export const deleteMessages = async (messageId: number | string) => {
    if (!messageId) {
        await Axios.instance().post(`message/read-all`);
    } else {
        await Axios.instance().post(`message/read/${messageId}`);
    }
};

export const truncateindexedDBObjects = async (stores: string[]) => {
    for (const store of stores) {
        await userRepository.truncate(store);
    }
};
