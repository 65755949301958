import {KDMaxProductRepository} from "IndexedDB";

const kdMaxProductRepository = new KDMaxProductRepository();

export const addImportedProducts = async (data) => {
    // await kdMaxProductRepository.truncate('kdmax_product');
    await kdMaxProductRepository.addProduct(data);
}

export const deleteImportedProduct = async (id) => {
    // await kdMaxProductRepository.truncate('kdmax_product');
    await kdMaxProductRepository.deleteProduct(id);
}

export const deleteAllImportedProduct = async (id) => {
    // await kdMaxProductRepository.truncate('kdmax_product');
    await kdMaxProductRepository.deleteAllProduct();
}

export const updateImportedProduct = async (
    id,
    isAdded = true,
    isValidated = true,
    jobCabinetId = null
) => {
    let product = await kdMaxProductRepository.getImportedProduct(id);
    product[0].isAdded = isAdded;
    product[0].isValidated = isValidated;
    product[0].jobCabinetId = jobCabinetId;

    await kdMaxProductRepository.updateProduct(product[0]);
}

export const updateCode = async(id, quickProduct, option)=> {
    let product = await kdMaxProductRepository.getImportedProduct(id);
    product[0].code = quickProduct.code;
    product[0].image = quickProduct.image;
    product[0].height = option.height;
    product[0].depth = option.depth;
    product[0].dimensionString = `${option.height} x ${option.width} x ${option.depth}`;
    product[0].isValidated = !option.hasError;
    product[0].hasError = option.hasError;
    product[0].isAvailable = true;
    await kdMaxProductRepository.updateProduct(product[0]);
    return;
}

export const getAllKDMaxProducts = async (roomId) => {
    return await kdMaxProductRepository.getAllProducts(roomId);
}

export const getKDMaxProduct = async (id) => {
    let importedProduct = await kdMaxProductRepository.getImportedProduct(id)
    importedProduct = (importedProduct.length) ? importedProduct[0] : {};
    return importedProduct;
}