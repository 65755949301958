const TDLD_DATA = 'cbc_tdld_data';

const utf8_to_b64 = (jsonString) => {
    return window.btoa(window.unescape(window.encodeURIComponent(jsonString)));
};

const b64_to_utf8 = (base64String) => {
    return window.decodeURIComponent(window.escape(window.atob(base64String)));
};

export const setRoomLayoutStatus = (roomId, status = true) => {
    let layoutData = window.localStorage.getItem(TDLD_DATA);

    if (layoutData == null) {
        layoutData = {};
    } else {
        layoutData = JSON.parse(b64_to_utf8(layoutData));
    }

    layoutData[roomId] = status;

    window.localStorage.setItem(
        TDLD_DATA,
        utf8_to_b64(JSON.stringify(layoutData)),
    );
};

export const getRoomLayoutStatus = (roomId) => {
    let layoutData = window.localStorage.getItem(TDLD_DATA);

    if (layoutData == null) {
        return false;
    }

    layoutData = JSON.parse(b64_to_utf8(layoutData));

    return layoutData.hasOwnProperty(roomId) && layoutData[roomId];
};
