import Axios from './Axios';
import {Response} from 'service';

interface ForgotPasswordInterface extends Response {
    message: string;
}

export const logout = async () => {
    await Axios.instance().post('/logout');
};

// TODO move all these functions to rtk-query
export const selectUserAccount = async (
    id: number,
    type = 2, // Customer
    keepLoggedIn = false
) => {
    const formData = new FormData();

    formData.set('id', `${id}`);
    formData.set('type', `${type}`);

    if (keepLoggedIn) {
        formData.set('keep_logged', '1');
    }

    await Axios.instance().post('/login/account', formData);
};

export const forgotPassword = async (email: string): Promise<string> => {
    const formData = new FormData();

    formData.set('email', email);

    const response = await Axios.instance().post<ForgotPasswordInterface>(
        '/account/forgot-password',
        formData
    );

    if (response.data.success) {
        return response.data.message;
    }

    return null;
};

export const resetPassword = async (
    id: number,
    token: string,
    password: string,
    confirmPassword: string
) => {
    const formData = new FormData();

    formData.set('id', `${id}`);
    formData.set('token', token);
    formData.set('password', password);
    formData.set('confirmPassword', confirmPassword);

    await Axios.instance().post('/account/reset-password', formData);
};
