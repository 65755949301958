import Axios from './Axios';

export const getJobs = async (limit = 10) => {
    const response = await Axios.instance().get(
        'jobs/dashboard?limit=' + limit
    );

    if (response.data.success) {
        return response.data.data;
    } else {
        throw response.data.errors;
    }
};

/**
 * If mocked by passing true parameter, default static texts are supplied. Texts are extracted from current UI.
 *
 * @param {boolean} mock Default value is false
 *
 * @return {Promise}
 */
export const getUpdateCenterData = async (mock = false) => {
    if (mock) {
        return [
            {
                message:
                    "Need some help getting started? Check out Part 1 of our Virtual Tour which goes through the process of setting up a room for your job click <a target='_blank' href='https://www.youtube.com/watch?v=2jCQVzTpkIM'>HERE</a>.",
            },
            {
                message:
                    "Something not quite working as it should? Please feel free to contact us on <a href='mailto:info@gocabinets.com.au'>info@gocabinets.com.au</a> or by calling <a href='tel:0352543274'>(03) 5254 3274</a> so we can correct any issues that you may be having.",
            },
            {
                message:
                    "Got a question? We may have already answered it in our FAQ's, click <a target='_blank' href='http://gocabinets.com.au/faqs/'>here</a>.",
            },
        ];
    } else {
        const response = await Axios.instance().get(`updates`);

        if (response.data.success) {
            return response.data.updates;
        }
    }
};
