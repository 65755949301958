import { InfoRepository } from "IndexedDB";
import Axios from "./Axios";

const infoRepository = new InfoRepository();

export const getInfo = async (id) => {
    let info = await infoRepository.getInfo(id);

    if (info.length == 0) {
        let response = await Axios.instance().get(`cabinets/more_info/${id}`);

        if (response.data.success) {
            info = response.data.infoText;
            await infoRepository.addInfo([{ id: id, infoText : info}]);

        } else throw response.data.errors;
    } else {
        info = info[0].infoText;
    }

    return info;
}

export const saveInfo = async (id, data) => {
    let response = await Axios.instance().post(
        `cabinets/more_info/`,
        {
            id : id,
            text: data
        },
    );

    if (response.data.success) {
        await infoRepository.deleteInfo(id);
        await infoRepository.addInfo([{ id: id, infoText : data}]);
    } else throw response.data.errors;
}