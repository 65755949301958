/**
 *
 * This module is used to configure axios instance.
 *
 * Initially use baseURL option is used, Configure axios as required in future.
 * Reference https://github.com/axios/axios#config-defaults for more information.
 */
import axios, {AxiosError, AxiosInstance} from 'axios';
import {Config} from 'config';

type SessionChangeResponse = {
    error: string;
    redirectTo: string;
    sessionChanged: boolean;
    success: number;
};

/**
 *
 *
 * @class Axios
 */
class Axios {
    static axiosInstance: AxiosInstance;

    static onLoggedOut: () => void;
    static onSuspendedUser: () => void;
    static onSessionChangedUser: (response: SessionChangeResponse) => void;

    /**
     *
     * @static
     * @param {string} [url='']
     * @return {OGAxios}
     * @memberof Axios
     *
     */
    static instance(url = ''): AxiosInstance {
        if (typeof Axios.axiosInstance === 'undefined') {
            Axios.axiosInstance = axios.create({
                baseURL: url != '' ? url : Config.api,
            });

            Axios.axiosInstance.interceptors.response.use(
                (response) => {
                    return response;
                },
                (error: AxiosError<{suspended: boolean; error: string}>) => {
                    if (
                        error.response &&
                        error.response.headers['x-co-authenticated'] == 'No' &&
                        Axios.onLoggedOut &&
                        typeof Axios.onLoggedOut === 'function'
                    ) {
                        Axios.onLoggedOut();
                    }

                    // if suspended user
                    if (
                        error.response &&
                        error.response.status === 403 &&
                        error.response.data.suspended &&
                        Axios.onSuspendedUser &&
                        typeof Axios.onSuspendedUser === 'function'
                    ) {
                        Axios.onSuspendedUser();
                    }

                    // if session_change user
                    if (
                        error.response &&
                        error.response.status === 401 &&
                        error.response.data.sessionChanged &&
                        Axios.onSessionChangedUser &&
                        typeof Axios.onSessionChangedUser === 'function'
                    ) {
                        Axios.onSessionChangedUser(error.response.data);
                    }

                    if (
                        error.response &&
                        error.response.data &&
                        error.response.data.error &&
                        typeof error.response.data.error === 'string'
                    ) {
                        return Promise.reject(
                            new Error(error.response.data.error)
                        );
                    }

                    return Promise.reject(error);
                }
            );
        }

        return Axios.axiosInstance;
    }
}

export default Axios;
